@import 'mixins';
.site-footer{
    width: 100%;
    height: 517px;
    background-color: $blue-black;
    // background-color: red;
    @include flexColumn(flex-start);
    // margin-top: 200px;

    &__up-line{
        width: 100%;
        height: 50px;
        padding: 20px 0px;
        // background-color: yellow;
        @include flexRow(center);
        border-bottom: 2px solid #4f5567;
        .site-footer__container{
            max-width: 1360px;
            width: 100%;
            height: 100%;
            // background-color: pink;
            @include flexRow(flex-start);
        }
    }


    &__logo-block{
        width: 135px;
        height: 40px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }

    &__logo{
        max-width: 100%;
        max-height: 100%;
    }

    &__middle-line{
        // background-color: yellow;
        width: 100%;
        height: 305px;
        padding: 30px 0px;
        @include flexRow(center);
        .site-footer__container{
            max-width: 1360px;
            width: 100%;
            height: 100%;
            // background-color: pink;
            @include flexRow(flex-start);
        }
    }

    &__contacts-block{
        width: 320px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items:  flex-start;
    }

    &__contact-link{
        font-size: 16px;
        font-weight: 700;
        color: $main-color;
        text-decoration: none;
        border-bottom: 1px solid $main-color;
        transition: 0.3s;
        &:hover{
            color: $red;
            border-color: $red;
        }
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__address{
        font-size: 16px;
        font-weight: 700;
        color: white;
        margin-top: 25px;
        width: 190px;
        // background-color: red;
    }

    &__button{
        width: 200px;
        height: 50px;
        background-color: $main-color;
        color: white;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        margin-top: 25px;
        text-decoration: none;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-color, 5%);
        }
    }

    &__menu{
        width: 220px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items:  flex-start;
        // margin-left: 10px;
        list-style: none;
    }

    &__menu-header{
        color: white;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__menu-item{
        margin-bottom: 10px;
    }
    
    &__menu-link{
        font-size: 14px;
        font-weight: 700;
        color: $main-light-dark;
        text-decoration: none;
        border-bottom: 1px solid $main-light-dark;
        transition: 0.3s;
        &:hover{
            color: $main-color;
            border-color: $main-color;
        }
    }

    &__pay-block{
        // background-color: yellow;
        width: 270px;
        height: 100%;
        // background-color: plum;
        @include flexRow(center);
        align-items:  flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        // margin-left: 12px;
    }

    &__pay-header{
        color: white;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__pay-img-block{
        width: 70px;
        height: 40px;
        border-radius: 10px;
        background-color: white;
        margin: 5px;
        @include flexRow(center);
        align-items: center;
    }

    &__pay-img{
        max-width: 80%;
        max-height: 80%;
    }

    &__bottom-line{
        width: 100%;
        height: 60px;
        background-color: #353b4d;
        @include flexRow(center);
        .site-footer__container{
            max-width: 1360px;
            width: 100%;
            height: 100%;
            // background-color: pink;
            @include flexRow(flex-start);
            align-items: center;
        }
    }

    &__rights-text{
        font-size: 12px;
        font-weight: 500;
        color: #7d818c;
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 10px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        // top: 0px;
        // right: 0px;
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__form-header{
        font-size: 16px;
        font-weight: 900;
        color: $red;
    }

    &__input-container{
        width: 100%;
        height: 75px;
        // background-color: orange;
        margin-top: 15px;
    }

    &__input-name{
        font-size: 14px;
        font-weight: 700;
    }

    &__input{
        margin-top: 8px;
        height: 48px;
        width: 218px;
        // background-color: red;
        padding: 0px 20px;
        border: 1px solid $main-light-gray;
        outline: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.3s;
        &:focus{
            // border-color: $red;
            box-shadow: 0px 0px 10px 0px $main-light-gray;
        }
    }

    &__button{
        margin-top: 15px;
        width: 100%;
        height: 50px;
        border: none;
        color: white;
        font-size: 14px;
        font-weight: 700;
        background-color: $main-color;
        cursor: pointer;
        transition: 0.3s;
        text-align: center;
        @include flexColumn(center);
        &:hover{
            background-color: darken($main-color, 5%);
        }
    }

    &__policy{
        width: 100%;
        height: 20px;
        // background-color: orange;
        margin-top: 15px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__policy-text{
        font-size: 11px;
        font-weight: 500;
        color: black;
    }

    &__policy-link{
        font-size: 11px;
        font-weight: 500;
        color: $main-color;
        margin-left: 10px;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            color: $red;
        }
    }

    &__form-message{
        // width: 100%;
        height: 200px;
        margin-top: 40px;
        // background-color: orange;
        text-align: center;
        @include flexColumn(center);
        font-size: 18px;
        font-weight: 500;
        color: black;
        display: none;
    }
}

@media (max-width: 1360px){
    .site-footer{
        // display: none;
        height: unset;

        &__up-line{
            @include flexRow(center);

            .site-footer__container{
                @include flexRow(center);
            }
        }

        &__middle-line{
            height: unset;
            .site-footer__container{
                @include flexRow(center);
                flex-wrap: wrap;
                align-items: center;
            }
        }

        &__contacts-block{
            align-items: center;
            margin: 20px;
        }

        &__menu{
            margin: 20px;
            align-items: center;
            text-align: center;
        }

        &__bottom-line{
            @include flexRow(center);
            .site-footer__container{
                @include flexRow(center);
                // background-color: red;
                text-align: center;
            }
        }
    }
}